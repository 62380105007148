<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container" style="padding: 20px">
      <div style="background-color: #fff;border-radius:10px;box-shadow: 0 0 10px rgba(0, 0, 0, .2); ">
        <el-row :gutter="0">
          <el-col :span="6" style="padding-top: 20px;">
            <el-tree :data="organizationTree" default-expand-all node-key="id" highlight-current
                     class="org-tree" :expand-on-click-node="false"
                     @node-click="nodeClick" :render-content="treeRenderContent">
            </el-tree>
          </el-col>
          <el-col :span="18">
            <page-table ref="table" show-paginate :get-data="userPage" :fields="fields" :search-model="searchModel"
                        :item-actions="actions" @onActionClick="handleItemActionClick">
              <template slot="search-items">
                <el-form-item prop="webSearchRoleId">
                  <el-select v-model="searchModel.webSearchRoleId" placeholder="角色">
                    <el-option :label="type.name" :value="type.id" v-for="type in roleOptions" :key="type.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="webSearchAuthOver">
                  <el-select v-model="searchModel.webSearchAuthOver" placeholder="账号状态">
                    <el-option :label="type.label" :value="type.value" v-for="type in userState" :key="type.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="webSearch">
                  <el-input v-model="searchModel.webSearch" placeholder="姓名 手机号"></el-input>
                </el-form-item>
              </template>
              <template slot="page-actions">
                <el-button @click="handleAdd" size="mini" type="primary">新增员工</el-button>
                <!--<el-button @click="handleUpload" size="mini" type="primary">表格导入</el-button>-->
              </template>
            </page-table>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog title="编辑员工" :visible.sync="page_dialog_visible" append-to-body :close-on-click-modal="false"
               width="35%" custom-class="min-w-750">
      <el-form :model="pageForm" :rules="pageRules" label-width="150px" ref="pageForm"
               v-loading="page_form_loading">
        <el-form-item label="账号" prop="username">
          <el-input placeholder="账号" v-model="pageForm.username" :disabled="pageForm.hasOwnProperty('id')"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="name">
              <el-input placeholder="姓名" v-model="pageForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="gender">
              <el-select v-model="pageForm.gender" placeholder="性别">
                <el-option label="男" :value="0"></el-option>
                <el-option label="女" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="头像" prop="headImg">
          <el-upload
                  action="-"
                  class="el-uploader"
                  style="--image-width: 100px;--image-height: 100px"
                  :show-file-list="false"
                  :http-request="handleThumbUpload">
            <img v-if="pageForm.headImg" :src="pageForm.headImg | getSourceUri"
                 class="el-uploader-image">
            <i v-else class="el-icon-plus el-uploader-icon"></i>
            <div class="el-upload__tip" slot="tip">只能上传image文件 1:1</div>
          </el-upload>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input placeholder="手机号" v-model="pageForm.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-form-item label="是否为电子签超管" prop="isTencentSignSuperAdmin">
            <el-checkbox v-model="pageForm.isTencentSignSuperAdmin"></el-checkbox>
          </el-form-item>
          <template v-if="pageForm.isTencentSignSuperAdmin">
            <el-form-item label="身份证号" prop="idNo" :rules="[{required: true, message: '请输入身份证号', trigger: 'blur'},]">
              <el-input placeholder="身份证号" v-model="pageForm.idNo"></el-input>
            </el-form-item>
          </template>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="归属组织" prop="tmp_organization_id">
              <el-cascader ref="organizationCascaderRef" v-model="pageForm.tmp_organization_id"
                           :options="organizationTree"
                           :show-all-levels="false" @change="organizationChange"
                           :props="{ checkStrictly: true, label: 'label', value: 'id' ,children: 'children' }"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户类型" prop="userType">
              <el-select disabled v-model="pageForm.userType" placeholder="用户类型">
                <el-option label="集团用户" value='admin'/>
                <el-option label="企业用户" value='enterprise'/>
                <el-option label="经销商" value='distributor'/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!--   <el-form-item label="小程序业务员" prop="isSalesman">
             <el-checkbox v-model="pageForm.isSalesman" :disabled="pageForm.userType == 'admin'"></el-checkbox>
           </el-form-item>
                  <template v-if="pageForm.isSalesman">
             <el-form-item label="身份证号" prop="idNo" :rules="[{required: true, message: '请输入身份证号', trigger: 'blur'},]">
               <el-input placeholder="身份证号" v-model="pageForm.idNo"></el-input>
             </el-form-item>
           </template>
           -->
        <el-form-item label="密码" prop="password"
                      :rules="pageForm.hasOwnProperty('id') ? [] : [{required: true, message: '请输入账号', trigger: 'blur'}]">
          <el-input placeholder="密码" v-model="pageForm.password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="twoPassword"
                      :rules="pageForm.hasOwnProperty('id') ? [] : [{required: true, message: '请输入账号', trigger: 'blur'}]">
          <el-input placeholder="确认密码" v-model="pageForm.twoPassword"></el-input>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="page_dialog_visible = false">取 消</el-button>
          <el-button @click="submit('pageForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!--开发商-->
    <el-dialog title="编辑开发商" :visible.sync="developer_dialog_visible" append-to-body
               :close-on-press-escape="false" :modal-append-to-body="false"
               :close-on-click-modal="false" width="40%" custom-class="min-w-750">
      <el-form :model="devPageForm" :rules="devRules" label-width="130px" ref="developerForm"
               v-loading="page_form_loading">
        <el-form-item label="名称" prop="name">
          <el-input placeholder="名称" v-model="devPageForm.name" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="统一信用代码" prop="unifiedCreditCode">
          <el-input placeholder="统一信用代码" v-model="devPageForm.unifiedCreditCode" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input placeholder="电话" v-model="devPageForm.phone" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="areas">
          <el-cascader ref="areaCascaderRef" v-model="devPageForm.areas" :options="pca_options"
                       @change="areaChange($event, 'devPageForm')"
                       :props="{ checkStrictly: false , label: 'name', value: 'name' ,children: 'children' }"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="devPageForm.address" placeholder="详细地址"></el-input>
        </el-form-item>
        <el-form-item label="法人" prop="legalMan">
          <el-input v-model="devPageForm.legalMan" placeholder="法人"></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号码" prop="idNo">
          <el-input v-model="devPageForm.idNo" placeholder="法人身份证号码"></el-input>
        </el-form-item>
        <el-form-item label="业务联系人" prop="contacts">
          <el-input v-model="devPageForm.contacts" placeholder="业务联系人"></el-input>
        </el-form-item>
        <el-form-item label="业务联系人电话" prop="contactsPhone">
          <el-input v-model="devPageForm.contactsPhone" placeholder="业务联系人电话"></el-input>
        </el-form-item>
        <el-form-item label="服务商ID" prop="dataId">
          <el-input v-model="devPageForm.dataId" placeholder="服务商ID"></el-input>
        </el-form-item>
        <el-form-item label="服务商密钥" prop="secretKey">
          <el-input v-model="devPageForm.secretKey" placeholder="服务商密钥"></el-input>
        </el-form-item>
        <el-form-item label="营业执照" prop="businessLicenseImg">
          <el-upload
                  action="-"
                  class="el-uploader"
                  style="--image-width: 320px;--image-height: 180px"
                  :show-file-list="false"
                  :http-request="(file) => handleThumbUpload(file,'devPageForm')">
            <img v-if="devPageForm.businessLicenseImg" :src="devPageForm.businessLicenseImg | getSourceUri"
                 class="el-uploader-image">
            <i v-else class="el-icon-plus el-uploader-icon"></i>
            <div class="el-upload__tip" slot="tip">只能上传image文件 16:9</div>
          </el-upload>
        </el-form-item>
        <!--电子签类型-->
        <el-form-item label="电子签类型" prop="signType">
          <el-select v-model="devPageForm.signType" clearable placeholder="电子签类型">
            <el-option v-for="(item, index) in signTypeOptions" :key="index" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商户编号" prop="no">
          <el-input v-model="devPageForm.no" placeholder="商户编号"></el-input>
        </el-form-item>
        <el-form-item label="电e宝账号名称" prop="ename">
          <el-input v-model="devPageForm.ename" placeholder="电e宝账号名称"></el-input>
        </el-form-item>
        <el-form-item label="电e宝账号" prop="e">
          <el-input v-model="devPageForm.e" placeholder="电e宝账号"></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="bankNo">
          <el-input v-model="devPageForm.bankNo" placeholder="银行账号"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bankName">
          <el-input v-model="devPageForm.bankName" placeholder="开户银行"></el-input>
        </el-form-item>
        <el-form-item label="银行联号" prop="bankJointNo">
          <el-input v-model="devPageForm.bankJointNo" placeholder="银行联号"></el-input>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="developer_dialog_visible = false">取 消</el-button>
          <el-button @click="developerSubmit('developerForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!--经销商表单-->
    <el-dialog title="编辑经销商" :visible.sync="distributor_dialog_visible" append-to-body
               :close-on-press-escape="false" :modal-append-to-body="false"
               :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <el-form :model="disPageForm" :rules="disRules" label-width="120px" ref="disPageForm"
               v-loading="page_form_loading">
        <el-form-item label="开发商" prop="developersId">
          <el-select v-model="disPageForm.developersId" placeholder="开发商" style="width: 100%;">
            <el-option v-for="item in developer_options" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <div></div>
        </el-form-item>
        <el-form-item label="经销商code">
          <el-input placeholder="系统自动生成" v-model="disPageForm.distributorCode" maxlength="20" disabled></el-input>
        </el-form-item>
        <el-form-item label="经销商名称" prop="name">
          <el-input placeholder="经销商名称" v-model="disPageForm.name" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="统一信用代码" prop="unifiedCreditCode">
          <el-input placeholder="统一信用代码" v-model="disPageForm.unifiedCreditCode" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="head">
          <el-input placeholder="负责人" v-model="disPageForm.head" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="负责人身份证" prop="idNo">
          <el-input placeholder="负责人身份证" v-model="disPageForm.idNo" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input placeholder="电话" v-model="disPageForm.phone" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="areas">
          <el-cascader ref="areaCascaderRef" v-model="disPageForm.areas" :options="pca_options"
                       @change="areaChange($event, 'disPageForm')"
                       :props="{ checkStrictly: false , label: 'name', value: 'name' ,children: 'children' }"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="disPageForm.address" placeholder="详细地址"></el-input>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="distributor_dialog_visible = false">取 消</el-button>
          <el-button @click="distributorSubmit('disPageForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!--提醒实名弹框-->
    <el-dialog title="提醒实名链接" :visible.sync="auth_dialog_visible" width="500px"
               :close-on-click-modal="false"
               :close-on-press-escape="false" :modal-append-to-body="false">
      <div>
        <div style="font-size: 18px;font-weight: bold;" id="auth-link">{{auth_link}}</div>
        <div style="margin-top: 20px;font-size: 12px;">温馨提示：5分钟有效，只能使用一次</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="copyLink">复制链接</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {
  organizationTree,
  userPage,
  userCreate,
  userUpdate,
  uploadFile,
  developerCreate,
  developerUpdate,
  developerInfo,
  distributorCreate,
  distributorUpdate,
  distributorInfo,
  developerOptions,
  realNameAuth,
  roleAll,
  userDelete,
  psnEmpowerAuth,
} from "@/api/common"
import pcaCode from "@/utils/pca-code"
import {PROVINCE_CODES, USER_STATE} from "@/utils/config"

export default {
  name: "Authorities",
  data() {
    return {
      organizationTree: null,
      searchModel: {
        webSearch: '',
        webSearchRoleId: '', // 角色
        webSearchAuthOver: '', // 账号状态
      },
      userPage: userPage,
      // 电子签类型
      signTypeOptions: [
        {label: 'e签宝', value: '1'},
        {label: '腾讯电子签', value: '0'},
      ],
      fields: [
        {label: 'UserId', key: 'id', width: 180},
        // {label: '账号', key: 'username', width: 120},
        {label: '姓名', key: 'name', width: 100,},
        {
          label: '性别', key: 'gender', width: 60,
          render({gender}, h) {
            return h('div', gender == 0 ? '男' : (gender == 1 ? '女' : ''))
          }
        },
        {label: '手机号', key: 'phone', width: 120},
        {
          label: '用户类型', key: 'userType', width: 100,
          render({userType}, h) {
            let types = {admin: '集团用户', enterprise: '企业用户', distributor: '经销商',}
            if (types[userType] !== undefined) {
              return h('div', types[userType])
            }
            return h('div')
          }
        },
        {
          label: '归属组织', width: 170,
          render: ({developers, distributor, userType}, h) => {
            if (!userType) {
              return h("span", '')
            } else if (userType == 'admin') {
              return h("span", "新能源")
            } else if (userType == 'enterprise') {
              return h("span", developers.name)
            } else if (userType == 'distributor') {
              return h("span", distributor.name)
            }
          }
        },
        {
          label: '角色权限', width: 120,
          render({roleList}, h) {
            let tmp = [];
            if (roleList) {
              for (let roleListElement of roleList) {
                tmp.push(roleListElement.name)
              }
            }
            return h('span', tmp.join('，'));
          }
        },
        {
          label: '业务权限', width: 170,
          render: ({developersList, distributorList}) => {
            let developerName = "";
            let str = "";
            if (developersList != null) {
              for (let roleListElement of developersList) {
                if (str === "") {
                  developerName = roleListElement.name;
                  str += roleListElement.name;
                } else {
                  str += "，" + roleListElement.name;
                }
              }
            }
            let one = true;
            if (distributorList != null && distributorList.length > 0) {
              str += developerName !== "" ? "-" : ""
              for (let roleListElement of distributorList) {
                if (one) {
                  if (developerName === "") {
                    developerName = roleListElement.name;
                  }
                  str += roleListElement.name;
                  one = false
                } else {
                  str += "，" + roleListElement.name;
                }
              }
            }
            return <el-tooltip placement="right-end">
              <div slot="content" > <div class="tip-box">{str}</div></div>
              <span>{developerName}</span>
              </el-tooltip>
          }
        },
        {
          label: '账号状态', width: 80,
          render({isSalesman, isAuthOver}, h) {
            if (isSalesman) {
              if (isAuthOver) {
                return h('span', {attrs: {class: 'color-blue'}}, '已实名')
              } else {
                return h('span', {attrs: {class: 'color-red'}}, '未实名')
              }
            } else {
              return h('span', '')
            }
          }
        },
        {label: '授权到期日期', key: 'eExpireTime', align: 'center', width: 180},
        {label: '创建时间', key: 'time', align: 'center', width: 180},
      ],
      actions: [
        {
          action: 'auth', label: '提醒实名', type: 'primary',
          showAction: item => {
            return (item.isSalesman && !item.isAuthOver)
          }
        },
        {
          action: 'accredit', label: '授权', type: 'primary',
          showAction: item => {
            return item.epsnId
          }
        },
        {
          action: 'edit', label: '编辑', type: 'primary', showAction: admin => {
            return !admin.is_supper_admin
          }
        },
        {action: 'del', label: '删除', type: 'primary'},
      ],
      //
      page_form_loading: false,
      // 员工表单
      pageForm: {
        username: '',
        name: '',
        gender: '',
        phone: '',
        tmp_organization_id: [],
        developersId: '',
        distributorId: '',
        userType: '',
        // isSalesman: '',
        isTencentSignSuperAdmin: false,
        idNo: '',
        password: '',
        twoPassword: '',
        headImg: ''
      },
      pageRules: {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        gender: [
          {required: true, message: '请选择性别', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
        ],
        tmp_organization_id: [
          {required: true, message: '请选择组织', trigger: 'blur'},
        ],
        userType: [
          {required: true, message: '请选择用户类型', trigger: 'blur'},
        ],
      },
      page_dialog_visible: false,
      // 开发商表单
      pca_options: pcaCode,
      developer_dialog_visible: false,
      devPageForm: {
        name: '',
        unifiedCreditCode: '',
        phone: '',
        provinceCode: '',
        province: '',
        city: '',
        area: '',
        areas: [],
        address: '',
        legalMan: '',
        idNo: '',
        contacts: '',
        contactsPhone: '',
        dataId: '',
        secretKey: '',
        businessLicenseImg: '',
        no: '',
        ename: '',
        e: '',
        bankNo: '',
        bankName: '',
        bankJointNo: '',
        signType: '1',// 电子签类型 0 腾讯电子签 1 e签宝
      },
      devRules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        unifiedCreditCode: [
          {required: true, message: '请输入统一信用代码', trigger: 'blur'},
        ],
        areas: [
          {required: true, message: '请选择地址', trigger: 'blur'},
        ],
        address: [
          {required: true, message: '请输入详细地址', trigger: 'blur'},
        ],
        legalMan: [
          {required: true, message: '请输入法人', trigger: 'blur'},
        ],
        idNo: [
          {required: true, message: '请输入身份证号', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
        ],
        no: [
          {required: true, message: '请输入商户编号', trigger: 'blur'},
        ],
        ename: [
          {required: true, message: '请输入电e宝名称', trigger: 'blur'},
        ],
        e: [
          {required: true, message: '请输入电e宝账户', trigger: 'blur'},
        ],
        dataId: [
          {required: true, message: '请输入服务商ID', trigger: 'blur'},
        ],
        secretKey: [
          {required: true, message: '请输入服务商密钥', trigger: 'blur'},
        ],
        businessLicenseImg: [
          {required: true, message: '请上传营业执照', trigger: 'blur'},
        ],
        signType: [
          {required: true, message: '请选择电子签类型', trigger: 'blur'},
        ],
      },
      // 经销商
      developer_options: [],
      distributor_dialog_visible: false,
      disPageForm: {
        developersId: '',
        distributorCode: '',
        name: '',
        unifiedCreditCode: '',
        head: '',
        idNo: '',
        phone: '',
        province: '',
        city: '',
        area: '',
        areas: [],
        address: '',
      },
      disRules: {
        developersId: [
          {required: true, message: '请选择开发商', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        unifiedCreditCode: [
          {required: true, message: '请输入统一信用代码', trigger: 'blur'},
        ],
        head: [
          {required: true, message: '请输入负责人', trigger: 'blur'},
        ],
        idNo: [
          {required: true, message: '请输入身份证号', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
        ],
        areas: [
          {required: true, message: '请选择地址', trigger: 'blur'},
        ],
        address: [
          {required: true, message: '请输入详细地址', trigger: 'blur'},
        ],
      },
      auth_dialog_visible: false,
      auth_link: null,
      roleOptions: [],
      userState: USER_STATE,
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {
    this.getRoles()
    this.getDeveloperOptions()
  },
  mounted() {
    this.getOrganizationTree()
  },
  methods: {
    getRoles() {
      let that = this;
      roleAll().then(res => {
        that.roleOptions = res;
      }).catch(() => {

      })
    },
    getOrganizationTree() {
      let that = this
      organizationTree(true).then(res => {
        res[0].disabled = false
        that.organizationTree = res
      })
    },
    getDeveloperOptions() {
      let that = this
      developerOptions().then(res => {
        that.developer_options = res
      })
    },
    // 提醒实名
    authAction(item) {
      let that = this
      if (!item.isAuthUser) {
        realNameAuth(item.id).then(res => {
          that.auth_link = res
          that.auth_dialog_visible = true
        })
      }
    },
    // e签宝
    accreditAction(item) {
      let that = this
      psnEmpowerAuth(item.id).then(res => {
        that.auth_link = res
        that.auth_dialog_visible = true
      })
    },
    // 复制链接
    copyLink() {
      let that = this
      const cInput = document.createElement('input')
      cInput.value = that.auth_link
      document.body.appendChild(cInput)
      cInput.select()
      document.execCommand('Copy')
      that.$message.success('复制成功')
      that.auth_dialog_visible = false
      cInput.remove()
    },
    handleThumbUpload(source, formName) {
      let that = this
      uploadFile(source.file).then(res => {
        if (formName === 'devPageForm') {
          that.devPageForm.businessLicenseImg = res
        } else {
          that.pageForm.headImg = res;
        }
        that.$forceUpdate();
      })
    },
    nodeClick(node) {
      switch (node.type) {
        case 'distributor':
          this.searchModel.distributorId = node.origin_id
          this.searchModel.developersId = ''
          break
        case 'developer':
          this.searchModel.developersId = node.origin_id
          this.searchModel.distributorId = ''
          break
        default:
          this.searchModel.distributorId = ''
          this.searchModel.developersId = ''
      }
      this.$refs.table.loadData()
    },
    treeRenderContent(h, {node, data}) {
      if (data.id === 'admin') {
        return (
          <span class="org-tree-node">
          <span>
            <el-tooltip  content={node.label} placement="top-start"> <span>{node.label}</span> </el-tooltip>
          </span>
          <span> <el-button type="text" on-click={ () => this.treeAppendAction(data) }>新增开发商</el-button> </span>
        </span>
      );
      } else {
        if (data.type == 'developer') {
          return (
            <span class="org-tree-node">
            <span>
              <el-tooltip  content={node.label} placement="top-start">
                  <span>{node.label}</span>
              </el-tooltip>
            </span>
            <span>
            <el-button type="text" on-click={ () => this.treeAppendAction(data) }>新增经销商</el-button>
            <el-button type="text" on-click={ () => this.treeEditAction(data) }>编辑</el-button>
          </span>
          </span>
        )
          }
        if(data.type == 'distributor'){
          return (
            <span class="org-tree-node">
              <span>
                <el-tooltip  content={node.label} placement="right"> <span>{node.label}</span> </el-tooltip>
              </span>
              <span> <el-button type="text" on-click={ () => this.treeEditAction(data) }>编辑</el-button> </span>
            </span>
        )}
      }
    },
    // tree新增
    treeAppendAction(data) {
      let that = this
      if (data.id === 'admin') {
        that.devPageForm = {
          name: '',
          unifiedCreditCode: '',
          phone: '',
          provinceCode: '',
          province: '',
          city: '',
          area: '',
          areas: [],
          address: '',
          legalMan: '',
          idNo: '',
          contacts: '',
          contactsPhone: '',
          dataId: '',
          secretKey: '',
          businessLicenseImg: '',
          no: '',
          ename: '',
          e: '',
          bankNo: '',
          bankName: '',
          bankJointNo: '',
          signType: '1'
        }
        that.developer_dialog_visible = true
      } else {
        that.disPageForm = {
          developersId: data.type == "developer" ? data.origin_id : '',
          distributorCode: '',
          name: '',
          unifiedCreditCode: '',
          head: '',
          idNo: '',
          phone: '',
          province: '',
          city: '',
          area: '',
          areas: [],
          address: '',
        }
        that.distributor_dialog_visible = true
      }
    },
    // tree编辑
    treeEditAction(data) {
      let that = this
      if (data.type == 'developer') {
        developerInfo(data.origin_id).then(item => {
          that.devPageForm = {
            id: item.id,
            name: item.name,
            unifiedCreditCode: item.unifiedCreditCode,
            phone: item.phone,
            provinceCode: item.provinceCode,
            province: item.province,
            city: item.city,
            area: item.area,
            areas: [item.province, item.city, item.area],
            address: item.address,
            legalMan: item.legalMan,
            idNo: item.idNo,
            contacts: item.contacts,
            contactsPhone: item.contactsPhone,
            dataId: item.dataId,
            secretKey: item.secretKey,
            businessLicenseImg: item.businessLicenseImg,
            no: item.no,
            ename: item.ename,
            e: item.e,
            bankNo: item.bankNo,
            bankName: item.bankName,
            bankJointNo: item.bankJointNo,
            version: item.version,
            signType: '1', // 电子签类型： 0 腾讯电子签； 1 e签宝
          }
          that.developer_dialog_visible = true
        })
      } else if (data.type == 'distributor') {
        distributorInfo(data.origin_id).then(item => {
          that.disPageForm = {
            id: item.id,
            developersId: item.developersId,
            distributorCode: item.distributorCode,
            name: item.name,
            unifiedCreditCode: item.unifiedCreditCode,
            head: item.head,
            idNo: item.idNo,
            phone: item.phone,
            province: item.province,
            city: item.city,
            area: item.area,
            areas: [item.province, item.city, item.area],
            address: item.address,
            version: item.version,
          }
          that.distributor_dialog_visible = true
        })
      }
    },
    developerSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.page_form_loading = true
        let func
        // eslint-disable-next-line no-prototype-builtins
        if (that.devPageForm.hasOwnProperty('id')) {
          func = developerUpdate
        } else {
          func = developerCreate
        }
        func(that.devPageForm).then(() => {
          that.page_form_loading = false
          that.$message.success('提交成功')
          that.developer_dialog_visible = false
          that.getOrganizationTree()
          that.getDeveloperOptions()
        }).catch(() => {
          that.page_form_loading = false
        })
      })
    },
    distributorSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) return false
        that.page_form_loading = true
        let func
        // eslint-disable-next-line no-prototype-builtins
        if (that.disPageForm.hasOwnProperty('id')) {
          func = distributorUpdate
        } else {
          func = distributorCreate
        }
        func(that.disPageForm).then(() => {
          that.page_form_loading = false
          that.$message.success('提交成功')
          that.distributor_dialog_visible = false
          that.getOrganizationTree()
        }).catch(() => {
          that.page_form_loading = false
        })
      })
    },
    areaChange(val, formName) {
      this[formName].province = val[0]
      this[formName].city = val[1]
      this[formName].area = val[2]
      let company = PROVINCE_CODES.find(item => {
        return item.province == val[0]
      })
      if (company) {
        this[formName].provinceCode = company.id
      }
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    handleAdd() {
      // this.pageForm = {
      //   username: '',
      //   name: '',
      //   gender: '',
      //   phone: '',
      //   tmp_organization_id: [],
      //   developersId: '',
      //   distributorId: '',
      //   userType: '',
      //   isTencentSignSuperAdmin: false,
      //   idNo: '',
      //   headImg: '',
      //   password: '',
      //   twoPassword: '',
      // }
      // this.page_dialog_visible = true
      this.$router.push({
        path: '/admin/add-staff',
        query: {'developersId': this.searchModel.developersId, 'distributorId': this.searchModel.distributorId}
      })
    },
    delAction(item) {
      let that = this
      that.$confirm('确认删除用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        userDelete(item.id).then(() => {
          that.$message.success('操作成功');
          that.$refs.table.loadData()
        }).catch(() => {
          that.$message.error('操作失败')
        })
      })
    },
    // handleUpload() {
    //   this.$router.push({path: '/admin/add-staff'})
    // },
    editAction(item) {
      let tmp_organization_id = ['admin']
      if (item.userType == 'admin') {
        tmp_organization_id = ['admin']
      } else if (item.userType == 'enterprise') {
        if (item.developersId) {
          tmp_organization_id.push('developer_' + item.developersId)
        }
      } else {
        if (item.developersId) {
          tmp_organization_id.push('developer_' + item.developersId)
        }
        if (item.distributorId) {
          tmp_organization_id.push('distributor_' + item.distributorId)
        }
      }
      this.pageForm = {
        id: item.id,
        username: item.username,
        name: item.name,
        gender: item.gender,
        phone: item.phone,
        tmp_organization_id: tmp_organization_id,
        developersId: item.developersId,
        distributorId: item.distributorId,
        userType: item.userType,
        // isSalesman: item.isSalesman,
        isTencentSignSuperAdmin: item.isTencentSignSuperAdmin,
        idNo: item.idNo,
        headImg: item.headImg,
        password: '',
        twoPassword: '',
        version: item.version
      }
      this.page_dialog_visible = true
    },
    organizationChange() {
      let nodes = this.$refs.organizationCascaderRef.getCheckedNodes()
      if (nodes.length < 1) return
      let current = nodes[nodes.length - 1]
      switch (current.data.type) {
        case 'admin':
          this.pageForm.userType = 'admin'
          this.pageForm.developersId = ''
          this.pageForm.distributorId = ''
          // this.pageForm.isSalesman = false
          this.pageForm.isTencentSignSuperAdmin = true
          break
        case 'developer':
          this.pageForm.userType = 'enterprise'
          this.pageForm.developersId = current.data.origin_id
          this.pageForm.distributorId = ''
          break
        case 'distributor':
          this.pageForm.userType = 'distributor'
          this.pageForm.developersId = current.data.parent_id
          this.pageForm.distributorId = current.data.origin_id
          break
      }
    },
    submit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.page_form_loading = true
        let func
        // eslint-disable-next-line no-prototype-builtins
        if (that.pageForm.hasOwnProperty('id')) {
          func = userUpdate
        } else {
          func = userCreate
        }
        func(that.pageForm).then(() => {
          that.page_form_loading = false
          that.$message.success('提交成功')
          that.page_dialog_visible = false
          that.$refs.table.loadData()
        }).catch(() => {
          that.page_form_loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.org-tree {
  /deep/ .org-tree-node {
    width: 90%;
    flex: 1;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }

  /deep/ .org-tree-node > span {
    display: inline-block;
    word-break: break-all;
  }

  /deep/ .org-tree-node > span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 66%;
  }
  /deep/ .org-tree-node > span:last-child{
    width: 30%;
    text-align: right;
  }

}
</style>
